export default{
	getUserRouters(state){
        return state.userRouters
    },
    getSubCabs(state){
        return state.subCabs
    },
    getCabInfo(state){
        return state.cabInfo
    },
    getNetworkInfo(state){
        return state.networkInfo
    }
}
