<template>
  <div class="container">
    <!-- <navigator url="/pages/home/home" open-type="switchTab" hover-class="other-navigator-hover"> -->
    <div class="content">
      <div class="LoginFrom">
        <div class="index_png">
          <img :src="Login_png" alt="" />
        </div>
        <div class="index_title">
          <text>程凯智控 账号登录</text>
        </div>
        <div class="from_item">
          <div class="from_value">
            <!-- <input v-model="loginfrom.username" placeholder="请输入账号" class="from_input" /> -->
            <el-input
              v-model="loginfrom.username"
              style="width: 260px; height: 35px"
              size="large"
              placeholder="请输入账号"
            />
          </div>
        </div>
        <div class="from_item">
          <div class="from_value">
            <!-- <input v-model="loginfrom.password" type="password" placeholder="请输入密码"
							class="from_input" /> -->
            <el-input
              size="large"
              v-model="loginfrom.password"
              style="width: 260px; height: 35px"
              type="password"
              show-password
              placeholder="请输入密码"
            />
          </div>
        </div>
        <div class="from_item agreement">
          <el-checkbox
            @change="checkAlarm"
            v-model="this.isChecked"
            label="已阅读并同意 用户协议"
            size="large"
          />
          <!-- <div>
							已阅读并同意 用户协议
						</div> -->
        </div>
        <div @click="toHome" class="loginBtn">登录</div>
      </div>
    </div>
    <!-- </navigator> -->
  </div>
</template>

<script>
import apiFun from "../network/api";
import { encode } from "../assets/common/js/encryption";
import { ElMessageBox } from "element-plus";
// import store from '@/store/index.js';
export default {
  data() {
    return {
      checkedInfo: "已阅读并同意 用户协议",
      Login_png: "",
      loginfrom: {
        username: "",
        password: "",
      },
      agreement: [
        {
          value: "user-agreement",
          checked: false,
        },
      ],
      isChecked: false,
      userId: "",
    };
  },
  methods: {
    checkAlarm() {
      this.isChecked == !this.isChecked;
      console.log(this.isChecked);
    },
    encodepassword(password, eles = []) {
      eles.push(password);
      return encode("0x12", eles);
    },
    toHome() {
      let data = {};
      data.username = this.loginfrom.username;
      data.password = this.encodepassword(this.loginfrom.password);
      if (this.isChecked === true) {
        apiFun
          .postLogin(data)
          .then((res) => {
            console.log("res===", res);
            if (res.code == 200) {
              this.roleId = res["result"].roles.roleId;
              console.log();
              // let that = this
              localStorage.setItem("token", res.token);
              localStorage.setItem("user_data", JSON.stringify(res.result));

              this.getUser();
              // store.commit('setLoginUser', res['result'])
              this.$router.push({ path: "/platform" });
              // console.log(res['result'])
              // uni.getStorage({
              // 	key: 'user_token',
              // 	success: function (res) {
              // 		console.log(res);
              // 	}
              // });
            } else {
              return;
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        console.log(this.agreement);
        ElMessageBox.alert("请先同意用户协议", "", {
          // 如果你想要禁用自动聚焦
          // autofocus: false,
          confirmButtonText: "OK",
        });
      }
    },
    getUser() {
      apiFun.postUserInfo().then((res) => {
        console.log("res.user", res);
        if (res.status == 400) {
          return;
        } else if (res.code && res.code == 200) {
          this.userId = res.user.userId;
          this.queryRole(this.userId);
          console.log("当前登录用户信息", res.user);
          localStorage.setItem("userId", res.user.userId);
          localStorage.setItem("mid", res.user.mid);
          console.log("res.user.mid", res.user.mid);
        }
      });
    },
    queryRole(userId) {
      console.log(userId);
      apiFun.getMenu(userId).then((res) => {
        let menu = res.data;
        console.log(menu);
        // store.commit("setMenu", menu);
        console.log("menu", res);
        // this.$router.push({ path: "/home" });
        // uni.switchTab({
        // 	url: '../home/home'
        // })
      });
    },
  },
  mounted() {
    if (window.innerWidth < 768) {
      window.location.href = window.Glob.baseH5_url;
    }
    // console.log(this.$store)
    if (localStorage.getItem("token")) {
      console.log("没有过期");
      this.getUser();
    } else {
      // console.log('过期了')
    }
    this.loginfrom = {
      username: "",
      password: "",
    };
    this.Login_png = window.Glob.Material + "/Designer_11.png";
    // console.log(this.Login_png);
    // 判断token是否失效
  },
};
</script>

<style   scoped>
/* @import url('~@/static/font/fontStyle.css'); */

.container {
  background: #f0f0f3;
  height: 100vh;
  padding: 0 !important;
  background-size: 100% 100%;
  background-attachment: fixed;
  overflow: hidden;
  font-family: alyuan;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoginFrom {
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.index_png img {
  width: 300px;
  height: 300px;
}

.index_title {
  margin: 0 auto 32px;
  font-size: 50px;
  font-family: alihei;
}

.from_item {
  display: flex;
  width: 100%;
  margin: 18px auto;
  justify-content: center;
}

.agreement {
  margin-top: 108px;
  font-family: alyuan;
  font-size: 20px;
}

.loginBtn {
  width: 380px;
  height: 80px;
  margin: 138px auto 0;
  text-align: center;
  line-height: 80px;
  font-size: 45px;
  border-radius: 110px;
  background: #005f7a;
  color: #f5f4f6;
  font-family: alihei;
}

/* 微信小程序兼容样式 */

.uni-easyinput {
  width: 1365px !important;
  height: 220px !important;
  border-radius: 73px !important;
  font-size: 73px !important;
  background: #d0d3d4 !important;
  color: #9e9e9e !important;
  font-family: alyuan;
}

.uni-easyinput__content {
  border-radius: 73px !important;
}

checkbox .wx-checkbox-input {
  width: 92px;
  height: 92px;
  border-radius: 50%;
  background: #d0d3d4;
}

checkbox .wx-checkbox-input.wx-checkbox-input-checked::before {
  width: 92px;
  height: 92px;
  line-height: 92px;
  border-radius: 50%;
  text-align: center;
  font-size: 73px;
  color: #fff;
  background: transparent;
  transform: translate(-50%, -50%) scale(1);
  -webkit-transform: translate(-50%, -50%) scale(1);
}
.el-checkbox__label {
  font-size: 18px !important;
}
.el-checkbox__inner {
  width: 25px !important;
  height: 25px !important;
}
.el-checkbox__inner::after{
  width: 9px !important;
  height: 8px !important;
}
</style>