<template>
    <netviewView></netviewView>
</template>

<script>
import netviewView from "../components/NetWorkView.vue"

export default {
  components: {
      netviewView,
  },
  data() {
      return {
      }
  }
}
</script>
