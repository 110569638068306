export default{
    userRouters: [],

    // 副柜数据
    subCabs:{
        list:[],
        No:''
    },

    // 器具柜数据
    cabInfo: {
        
    },

    // 网络设备数据
    networkInfo: {

    }
}
